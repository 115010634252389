import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoAdd, LazySvgoAddFile, LazySvgoArrowDownShort, LazySvgoArrowLeft, LazySvgoArrowRight, LazySvgoArrowRightShort, LazySvgoArrowUpShort, LazySvgoBag, LazySvgoBookmark, LazySvgoCalendar, LazySvgoCart, LazySvgoCheckCircle, LazySvgoChevronDown, LazySvgoChevronLeft, LazySvgoChevronLeftThin, LazySvgoChevronLeftThinS, LazySvgoChevronNext, LazySvgoChevronPrev, LazySvgoChevronRight, LazySvgoChevronRightThin, LazySvgoChevronRightThinS, LazySvgoChevronUp, LazySvgoClose, LazySvgoDiamond, LazySvgoEmail, LazySvgoExit, LazySvgoExpandLess, LazySvgoExpandMore, LazySvgoFaq, LazySvgoFilter, LazySvgoFourColumns, LazySvgoGoogle, LazySvgoGrid, LazySvgoHeartBold, LazySvgoHeartRounded, LazySvgoHeart, LazySvgoInfoFilled, LazySvgoInfo, LazySvgoMarker, LazySvgoMenu, LazySvgoMessage, LazySvgoMinus, LazySvgoOneColumn, LazySvgoPhone, LazySvgoPlus, LazySvgoRadioChecked, LazySvgoRadioUnchecked, LazySvgoRotateCcw, LazySvgoSearchNoResults, LazySvgoSearch, LazySvgoStar, LazySvgoStarFilled, LazySvgoStarOutlined, LazySvgoTrash, LazySvgoTruck, LazySvgoTwoColumns, LazySvgoUserCircle, LazySvgoUser } from '#components'
const lazyGlobalComponents = [
  ["SvgoAdd", LazySvgoAdd],
["SvgoAddFile", LazySvgoAddFile],
["SvgoArrowDownShort", LazySvgoArrowDownShort],
["SvgoArrowLeft", LazySvgoArrowLeft],
["SvgoArrowRight", LazySvgoArrowRight],
["SvgoArrowRightShort", LazySvgoArrowRightShort],
["SvgoArrowUpShort", LazySvgoArrowUpShort],
["SvgoBag", LazySvgoBag],
["SvgoBookmark", LazySvgoBookmark],
["SvgoCalendar", LazySvgoCalendar],
["SvgoCart", LazySvgoCart],
["SvgoCheckCircle", LazySvgoCheckCircle],
["SvgoChevronDown", LazySvgoChevronDown],
["SvgoChevronLeft", LazySvgoChevronLeft],
["SvgoChevronLeftThin", LazySvgoChevronLeftThin],
["SvgoChevronLeftThinS", LazySvgoChevronLeftThinS],
["SvgoChevronNext", LazySvgoChevronNext],
["SvgoChevronPrev", LazySvgoChevronPrev],
["SvgoChevronRight", LazySvgoChevronRight],
["SvgoChevronRightThin", LazySvgoChevronRightThin],
["SvgoChevronRightThinS", LazySvgoChevronRightThinS],
["SvgoChevronUp", LazySvgoChevronUp],
["SvgoClose", LazySvgoClose],
["SvgoDiamond", LazySvgoDiamond],
["SvgoEmail", LazySvgoEmail],
["SvgoExit", LazySvgoExit],
["SvgoExpandLess", LazySvgoExpandLess],
["SvgoExpandMore", LazySvgoExpandMore],
["SvgoFaq", LazySvgoFaq],
["SvgoFilter", LazySvgoFilter],
["SvgoFourColumns", LazySvgoFourColumns],
["SvgoGoogle", LazySvgoGoogle],
["SvgoGrid", LazySvgoGrid],
["SvgoHeartBold", LazySvgoHeartBold],
["SvgoHeartRounded", LazySvgoHeartRounded],
["SvgoHeart", LazySvgoHeart],
["SvgoInfoFilled", LazySvgoInfoFilled],
["SvgoInfo", LazySvgoInfo],
["SvgoMarker", LazySvgoMarker],
["SvgoMenu", LazySvgoMenu],
["SvgoMessage", LazySvgoMessage],
["SvgoMinus", LazySvgoMinus],
["SvgoOneColumn", LazySvgoOneColumn],
["SvgoPhone", LazySvgoPhone],
["SvgoPlus", LazySvgoPlus],
["SvgoRadioChecked", LazySvgoRadioChecked],
["SvgoRadioUnchecked", LazySvgoRadioUnchecked],
["SvgoRotateCcw", LazySvgoRotateCcw],
["SvgoSearchNoResults", LazySvgoSearchNoResults],
["SvgoSearch", LazySvgoSearch],
["SvgoStar", LazySvgoStar],
["SvgoStarFilled", LazySvgoStarFilled],
["SvgoStarOutlined", LazySvgoStarOutlined],
["SvgoTrash", LazySvgoTrash],
["SvgoTruck", LazySvgoTruck],
["SvgoTwoColumns", LazySvgoTwoColumns],
["SvgoUserCircle", LazySvgoUserCircle],
["SvgoUser", LazySvgoUser],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
